import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

// component
const useConfig = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                        keywords
                        image
                        logo
                        email
                    }
                }
            }
        `
    );

    return site.siteMetadata;
};

export default useConfig;
