import * as React from "react";
import { Helmet } from "react-helmet";
import useConfig from "./useConfig";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import { globalHistory } from "@reach/router";

// compoent props
interface SeoProps {
    /** page title */
    title: string;
    /** page description */
    description?: string;
    /** page image */
    image?: string;
    /** page language */
    language?: "it" | "en" | "es" | "de";
    /** page type */
    type: "WebPage" | "ItemPage" | "AboutPage" | "FAQPage" | "QAPage" | "ProfilePage" | "ContactPage" | "CollectionPage" | "SearchResultsPage";
    /** list of readmore link */
    readMore?: string[];
    /** additional schema */
    schema?: Object[];
}

// component
const SEO = (props: SeoProps) => {
    const { title, description, language, type, readMore, image, schema } = props;
    const siteMetadata = useConfig();

    // set SEO meta from props and basic gatsby configuration
    const keywords = siteMetadata.keywords;
    const metaDescription = description || siteMetadata.description;
    const defaultTitle = siteMetadata?.title;
    const siteUrl = siteMetadata?.siteUrl;
    const email = siteMetadata?.email;
    const logo = `${siteUrl}${siteMetadata?.logo}`;
    const seoImage = `${siteUrl}${image || siteMetadata.image}`;

    const potentialAction =
        readMore?.map((link) => {
            return { "@type": "ReadAction", target: [`${siteMetadata.siteUrl}${link}`] };
        }) || [];

    const siteSchema = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Corporation",
                "@id": `${siteUrl}/#organization`,
                name: defaultTitle,
                url: siteUrl,
                email: email,
                sameAs: [],
                logo: {
                    "@type": "ImageObject",
                    "@id": `${siteUrl}/#logo`,
                    inLanguage: "it-IT",
                    url: logo,
                    contentUrl: logo,
                    width: 1004,
                    height: 693,
                    caption: defaultTitle,
                },
                image: { "@id": `${siteUrl}/#logo` },
            },
            {
                "@type": "WebSite",
                "@id": `${siteUrl}/#website`,
                url: siteUrl,
                name: defaultTitle,
                description: siteMetadata.description,
                publisher: { "@id": `${siteUrl}/#organization` },
                potentialAction: [],
                inLanguage: "it-IT",
            },
            {
                "@type": type,
                "@id": `${siteMetadata.siteUrl}/#webpage`,
                url: siteMetadata.siteUrl,
                name: `${siteMetadata.title} &ndash; ${defaultTitle}`,
                isPartOf: { "@id": `${siteMetadata.siteUrl}/#website` },
                about: { "@id": `${siteMetadata.siteUrl}/#organization` },
                description: metaDescription,
                breadcrumb: { "@id": `${siteMetadata.siteUrl}/#breadcrumb` },
                inLanguage: language || "it-IT",
                potentialAction: potentialAction,
            },
            {
                "@type": "BreadcrumbList",
                "@id": `${siteMetadata.siteUrl}/#breadcrumb`,
                itemListElement: [
                    { "@type": "ListItem", position: 1, name: "Home", item: `${siteMetadata.siteUrl}` },
                    { "@type": "ListItem", position: 2, name: siteMetadata.title, item: `${siteMetadata.siteUrl}${globalHistory.location.pathname}` },
                ],
            },
            ...(schema || []),
        ],
    };

    return (
        <Helmet
            htmlAttributes={{ lang: language || "it" }}
            title={title}
            titleTemplate={`%s | ${defaultTitle}`}
            meta={[
                { name: `format-detection`, content: "telephone=no" },
                { name: `description`, content: metaDescription },
                { name: `keywords`, content: keywords },
                { property: `og:title`, content: title },
                { property: `og:description`, content: metaDescription },
                { property: `og:type`, content: `website` },
                { property: `og:image`, content: seoImage },
                { name: `twitter:card`, content: `summary` },
                { name: `twitter:image`, content: seoImage },
                { name: `twitter:title`, content: title },
                { name: `twitter:description`, content: metaDescription },
                { name: `format-detection`, content: "telephone=no" },
            ]}
        >
            <script type="application/ld+json">{JSON.stringify(siteSchema)}</script>
        </Helmet>
    );
};

export default SEO;
