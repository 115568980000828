import * as React from "react";
import { Typography, Stack, Box } from "@mui/material";

// components
import Link from "../utils/link";
import SocialLinks from "../utils/social-links";

// markup
const Address = () => {
    return (
        <Stack spacing={2}>
            <Typography variant="h4" component="p">
                <strong>Mettifogo S.r.l</strong>
            </Typography>

            <Typography>Via Torre Orti, 2 - 37030 Vago di Lavagno (VR)</Typography>

            <Typography>
                dal lunedì al giovedì: 8.00 - 12.00 / 14.00 - 18.00
                <br />
                venerdì: 8.00 - 12.30 / 13.30 - 17.00
                <br />
            </Typography>

            <Link type="external" href="tel:+390458980097" title="Chiama">
                Telefono: 045 8980097
            </Link>

            <Link type="external" href="mailto:info@mettifogo.eu" title="Scrivi email">
                Email: info@mettifogo.eu
            </Link>

            <Typography variant="caption">
                P.IVA: 00250380235 - REA VR 115247
                <br />
                Capitale Sociale i.v. € 40.000,00
            </Typography>
        </Stack>
    );
};

export default Address;
