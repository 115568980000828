import * as React from "react";
import { alpha, Grid, Stack, Typography, Box } from "@mui/material";

// utils
import Section, { SectionProps } from "./section";
import theme from "./theme";

interface HeroProps extends SectionProps {
    /** page title */
    title?: string | JSX.Element;
    /** page subtitle */
    description?: string | JSX.Element;
    /** hero image */
    image?: JSX.Element;
}

const Hero = (props: HeroProps) => {
    const { title, description, image } = props;
    const sx = !(title || description || image) && { py: 6 };

    return (
        <Box style={{ background: theme.palette.primary.main }}>
            <Section {...props} sx={sx}>
                {(title || description || image) && (
                    <Box pt={12}>
                        <Grid container spacing={5} alignItems="center" justifyContent="space-between">
                            <Grid item xs={12} md={image ? 6 : 8}>
                                <Stack spacing={2}>
                                    <Typography variant="h2" component="h1">
                                        {title}
                                    </Typography>

                                    {description && <Typography component="div">{description}</Typography>}
                                </Stack>
                            </Grid>
                            {image && (
                                <Grid item xs={12} md={6}>
                                    {image}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
            </Section>
        </Box>
    );
};

export default Hero;
