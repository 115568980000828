// package
import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import "./theme.css";

const FONT_SIZE_H1 = 3.5;
const FONT_SIZE_H2 = 2.5;
const FONT_SIZE_H3 = 2.0;
const FONT_SIZE_H4 = 1.8;
const FONT_SIZE_H5 = 1.5;
const FONT_SIZE_H6 = 1.5;
const breakpoints = createBreakpoints({});

const titleStyles = (size: number) => {
    return {
        fontWeight: 600,
        [breakpoints.down("sm")]: { fontSize: `${Math.max(size * 0.6, 1.4)}em` },
        [breakpoints.between("sm", "md")]: { fontSize: `${Math.max(size * 0.7, 1.4)}em` },
        [breakpoints.between("md", "lg")]: { fontSize: `${Math.max(size * 0.8, 1.4)}em` },
    };
};

// https://material-ui.com/customization/default-theme/
const theme = createTheme({
    palette: {
        primary: {
            main: "#417a26",
            contrastText: "#fff",
        },
        secondary: {
            main: "#f4b51c",
            contrastText: "#ffffff",
        },
        info: {
            main: "#484D59",
            contrastText: "#fff",
        },
        background: {
            default: "#fff",
        },
        text: {
            primary: "#303030",
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: ["Outfit", "sans-serif"].join(","),

        // Lato Bold (700) for all heading styles

        h1: { fontSize: `${FONT_SIZE_H1}em`, ...titleStyles(FONT_SIZE_H1) },
        h2: { fontSize: `${FONT_SIZE_H2}em`, ...titleStyles(FONT_SIZE_H2) },
        h3: { fontSize: `${FONT_SIZE_H3}em`, ...titleStyles(FONT_SIZE_H3) },
        h4: { fontSize: `${FONT_SIZE_H4}em`, ...titleStyles(FONT_SIZE_H4) },
        h5: { fontSize: `${FONT_SIZE_H5}em`, ...titleStyles(FONT_SIZE_H5) },
        h6: { fontSize: `${FONT_SIZE_H6}em`, ...titleStyles(FONT_SIZE_H6) },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 4,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    paddingLeft: 40,
                    paddingRight: 40,
                    [breakpoints.down("sm")]: {
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
            },
        },
    },
});

export default theme;
