// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDLLMJxxeunn0bmbQi6-_MZ3Ll4Lbt6gW8",
    authDomain: "mettifogo-salviette.firebaseapp.com",
    projectId: "mettifogo-salviette",
    storageBucket: "mettifogo-salviette.appspot.com",
    messagingSenderId: "548956145337",
    appId: "1:548956145337:web:2cbce3151c5545b194bd68",
    measurementId: "G-7YS6CKCG2G",
};

// navigation menu
export const navigation = [
    { label: "Home", url: "/" },
    { label: "Chi siamo", url: "/chi-siamo" },
    { label: "Salviette", url: "/salviette-monouso" },
    { label: "Bavagli", url: "/bavagli-in-tnt" },
    { label: "Personalizza", url: "/personalizza-prodotti" },
    { label: "Campione gratuito", url: "/richiedi-campione-gratuito" },
    { label: "Contatti", url: "/contatti" },
];
