import * as React from "react";
import { ThemeProvider, Grid, Stack, Typography, CssBaseline, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// utils
import { navigation } from "../config";
import Header from "./header";
import theme from "./theme";
import SEO from "./seo";
import Section from "./section";
import Link from "./link";

// component
import Address from "../components/address";

interface PageProps {
    /** page title */
    title: string;
    /** page subtitle */
    description: string;
    /** page image */
    image?: string;
    /** page type */
    type:
        | "WebPage"
        | "ItemPage"
        | "AboutPage"
        | "FAQPage"
        | "QAPage"
        | "ProfilePage"
        | "ContactPage"
        | "CollectionPage"
        | "SearchResultsPage";
    /** list of readmore link */
    readMore?: string[];
    /** page content */
    children: JSX.Element | JSX.Element[];
    /** additional schema */
    schema?: Object[];
}

const Page = (props: PageProps) => {
    const { title, description, children, type, schema, image } = props;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <SEO title={title} description={description} type={type} schema={schema} image={image} />

            <Header collapse="lg" background={theme.palette.primary.main} navigation={navigation} />

            {children}

            <Section background={theme.palette.grey[100]}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={5}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11203.426658234346!2d11.1258547!3d45.4122299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf315f7349d4d0314!2sMettifogo!5e0!3m2!1sit!2sit!4v1641317272091!5m2!1sit!2sit"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            loading="lazy"
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Address />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                            <Typography variant="caption">
                                © {new Date().getFullYear()} - Mettifogo S.r.l Unipersonale
                            </Typography>
                            <Typography variant="caption">
                                <Link type="internal" href="/chi-siamo" title="Scopri di piu su chi siamo">
                                    chi siamo
                                </Link>
                            </Typography>
                            <Typography variant="caption">
                                <Link
                                    title="Privacy policy"
                                    type="external"
                                    href="https://www.iubenda.com/privacy-policy/97449482"
                                >
                                    privacy policy
                                </Link>
                            </Typography>
                            <Typography variant="caption">
                                <Link
                                    title="Credits - Federico Solfa"
                                    type="external"
                                    href="https://www.federicosolfa.it"
                                >
                                    credits
                                </Link>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Section>
        </ThemeProvider>
    );
};

export default Page;
